<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $vuetify.lang.t("$vuetify.firstName") }}
        </th>
        <th class="text-left">
          {{ $vuetify.lang.t("$vuetify.variants.cant") }}
        </th>
        <th class="text-left">
          {{ $vuetify.lang.t("$vuetify.articles.price_product") }}
        </th>
        <th class="text-left">
          {{ $vuetify.lang.t("$vuetify.articles.total_price_product") }}
        </th>
        <th class="text-left">
          {{ $vuetify.lang.t("$vuetify.articles.new_inventory") }}
        </th>
        <template
          v-if="item.state === 'accepted'"
        >
          <th class="text-left">
            {{ $vuetify.lang.t("$vuetify.actions.actions") }}
          </th>
        </template>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="sale_article_shop in item.sale_article_shops"
        :key="sale_article_shop.id"
      >
        <template v-if="sale_article_shop.article_shops">
          <template>
            <td v-if="sale_article_shop.article_shops.article">
              {{ sale_article_shop.article_shops.article.nameComplete }}
            </td>
            <td v-else>{{ sale_article_shop.article_shops.article_deleted.nameComplete }}</td>
          </template>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <b>
                  <v-icon
                    v-if=" sale_article_shop.cantRefund.cant > 0"
                    style="color: red"
                    class="mr-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </b>
              </template>
              <span>
                      {{
                  $vuetify.lang.t("$vuetify.menu.refund") + ": " + parseFloat(sale_article_shop.cantRefund.cant).toFixed(2)
                }}
                    </span>
            </v-tooltip>
            {{ parseFloat(sale_article_shop.cant).toFixed(2) }}
            {{ getUM(sale_article_shop) }}
          </td>
          <td>
            <v-tooltip
              v-if="parseFloat(sale_article_shop.article_shops.price).toFixed(2) !== parseFloat(sale_article_shop.totalPrice).toFixed(2)"
              top>
              <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{ `${parseFloat(sale_article_shop.price).toFixed(2) + getCurrency}` }}
                      </span>
              </template>
              <span>
                        {{
                  $vuetify.lang.t('$vuetify.sale.update_price')
                }}: {{ `${sale_article_shop.article_shops.price + ' ' + getCurrency}` }}
                      </span>
            </v-tooltip>
            <span v-else>
                      {{ `${sale_article_shop.price + ' ' + getCurrency}` }}
                    </span>
          </td>
          <td>
            <span>{{
                `${parseFloat(sale_article_shop.totalPrice).toFixed(2) + ' ' + getCurrency}`
              }}</span>
            <template v-if="sale_article_shop.sale_article_taxes.length > 0 || sale_article_shop.discounts.length > 0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <b>
                    <v-icon
                      :color="sale_article_shop.cantRefund.money > 0 ? 'red': 'primary'"
                      class="mr-2"
                      small
                      v-bind="attrs"
                      v-on="on">
                      mdi-information
                    </v-icon>
                  </b>
                </template>
                <template>
                  <detail-article-price
                    :article="sale_article_shop"
                    :currency="getCurrency"
                  />
                  <span v-if="sale_article_shop.cantRefund.money > 0">
                                                        {{
                      $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                      `${sale_article_shop.cantRefund.money + ' ' + getCurrency}`
                    }}</span>
                </template>
              </v-tooltip>
            </template>
          </td>
          <td>
            <template v-if="sale_article_shop.article_shops.inventory > 0">
              {{ parseFloat(sale_article_shop.article_shops.inventory).toFixed(2) }}
              {{ getUM(sale_article_shop) }}
            </template>
            <template v-else>
              <i style="color: red">
                <v-icon
                  style="color: red"
                >
                  mdi-arrow-down-bold-circle
                </v-icon>
                {{ parseFloat(sale_article_shop.article_shops.inventory).toFixed(2) }}
                {{ getUM(sale_article_shop) }}
              </i>
            </template>
          </td>
          <template v-if="item.state === 'accepted'">
            <td>
              <template v-if="item.state === 'accepted'">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <b>
                      <v-icon
                        style="color: #ff752b"
                        class="mr-2"
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="refundArticle(item, sale_article_shop)"
                      >
                        mdi-undo
                      </v-icon>
                    </b>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                </v-tooltip>
              </template>
              <template v-else>
                <v-tooltip top>
                  <template
                    v-slot:activator="{
                                  on,
                                  attrs
                                }"
                  >
                    <b>
                      <v-icon
                        v-if="
                                    sale_article_shop.cant >
                                      0
                                  "
                        style="color: #ff752b"
                        class="mr-2"
                        small
                        v-bind="
                                    attrs
                                  "
                        v-on="
                                    on
                                  "
                        @click="
                                    cancelProductPreform(
                                      item, sale_article_shop.article_shops
                                    )
                                  "
                      >
                        mdi-cancel
                      </v-icon>
                    </b>
                  </template>
                  <span>{{
                      $vuetify.lang.t(
                        "$vuetify.actions.refund"
                      )
                    }}</span>
                </v-tooltip>
              </template>
            </td>
          </template>
        </template>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import DetailArticlePrice from './DetailArticlePrice'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductSale',
  components: {
    DetailArticlePrice
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'access_permit']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  methods: {

    getUM (item) {
      return (item.article_shops.article.um.name)
    }
  }
}
</script>

<style scoped>

</style>
