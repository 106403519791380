<template>
  <v-dialog
    v-model="toogleShowModal"
    max-width="100mm"
    persistent
  >
    <div :id="editSale.state === 'cancelled' ? 'watermark' : 'no_watermark'">
      <v-card width="100%">
        <v-card-text>
          <div
            id="ticket"
            class="ticket"
          >
            <img
              v-if="user.company.logo"
              style="margin-top: 15px"
              class="profile mx-auto d-block"
              :src="user.company.logo.original_url"
              alt="LOGO"
            >
            <p class="centrado">
              {{ $vuetify.lang.t("$vuetify.sale.fiscal") }}<br>
              <b><i>{{ editSale.company.address }}</i></b><br>
              <b><i>{{ editSale.shop.name }}</i></b><br>
              <b>{{ $vuetify.lang.t("$vuetify.tax.noFacture") }}</b>: {{ editSale.no_fiscal }}<br>
              {{ new Date(editSale.updated_at).toUTCString() }}<br>
              <b>{{ $vuetify.lang.t('$vuetify.menu.coin') + ': ' }}</b>{{getCurrency }}<br>
              <b>{{ $vuetify.lang.t("$vuetify.menu.box") + ": " }}</b>
              {{ editSale.box.name }}<br>
              <b>{{ $vuetify.lang.t('$vuetify.fiscal.sender') + ': ' }}</b>
              <b>{{ fiscalConfig.rnc }}</b><br>
              <b>{{ $vuetify.lang.t('$vuetify.fiscal.receiver') + ': ' }}</b>
              <b>{{editSale.client.rnc }}</b>
            </p>
            <p v-if="user.company.slogan">
              {{ user.company.slogan.toUpperCase()}}<br>
            </p>
            <div style="width: 100%; margin-bottom: 20px">
              <v-col
                style="width: 100%;padding: inherit"
                cols="12"
                md="12"
              >
                <table style="width: 100%; border-bottom-style: double;border-top-style:double">
                  <tr>
                    <th class="cantidad">
                      {{ $vuetify.lang.t('$vuetify.report.cant').toUpperCase() }}
                    </th>
                    <th class="producto">
                      {{ $vuetify.lang.t('$vuetify.menu.article').toUpperCase() }}
                    </th>
                    <th class="precio">
                      $$
                    </th>
                  </tr>
                </table>
                <p style="margin-top: 15px; text-align: center;margin-bottom: 10px;">
                  ***********************************
                </p>
              </v-col>
              <v-col
                v-for="(sale_article_shop,i) in editSale.sale_article_shops"
                :key="i"
                style="width: 100%;padding: inherit"
                cols="12"
                md="12"
              >
                <table style="width: 100%; border-bottom-style: dotted; margin-bottom: 15px">
                  <tbody>
                  <tr v-if="sale_article_shop.article_shops">
                    <td class="cantidad" width="30px"> {{ sale_article_shop.cant }} {{ getUM(sale_article_shop) }}</td>
                    <td class="producto" v-if="sale_article_shop.article_shops">
                      {{ sale_article_shop.article_shops.article.nameComplete }}<br>
                      <template
                        v-if="sale_article_shop.article_shops.article.variants.length > 0">
                        <span style="margin-bottom: 0px; margin-top: 0px"><b>{{ $vuetify.lang.t('$vuetify.menu.detail') }}:</b></span><br>
                        <span v-for="(property, i) in sale_article_shop.article_shops.article.variants" :key="i"
                           style="margin-bottom: 0px; margin-top: 0px"><b>{{ property.name }}:</b> {{ property.value }}</span>
                      </template>
                    </td>
                    <td class="precio">
                      {{ `${parseFloat(sale_article_shop.subTotal).toFixed(2)}` + getCurrency }}
                    </td>
                  </tr>
                  <template v-if="sale_article_shop.discounts.length >0">
                    <tr>
                      <td
                        colspan="3"
                        style="width: 100%"
                      >
                        <table
                          style="width: 100%"
                        >
                          <tbody>
                          <tr
                            v-for="(lDiscount, j) of sale_article_shop.discounts"
                            :key="j"
                          >
                            <td>
                              {{ $vuetify.lang.t('$vuetify.menu.discount') }}
                            </td>
                            <td>
                              {{ lDiscount.name }}{{ lDiscount.percent ? '(' + lDiscount.cant + '%) ' : ' ' }}
                            </td>
                            <td style="text-align: center">
                              <i>
                                -{{ lDiscount.percent ? parseFloat(lDiscount.cant * (sale_article_shop.subTotal + sale_article_shop.totalModifier) / 100).toFixed(2) + ' ' + getCurrency : parseFloat(lDiscount.cant).toFixed(2) + ' ' + getCurrency }}
                              </i>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <template
                    v-if="sale_article_shop.sale_article_taxes ?   sale_article_shop.sale_article_taxes : false">
                    <tr>
                      <td
                        colspan="3"
                        style="width: 100%"
                      >
                        <table
                          style="width: 100%"
                        >
                          <tbody>
                          <tr
                            v-for="(tax, l) in sale_article_shop.sale_article_taxes ? sale_article_shop.sale_article_taxes : []"
                            :key="l"
                          >
                            <td>
                              {{ $vuetify.lang.t('$vuetify.articles.tax') }}
                            </td>
                            <td>
                              {{ tax.name }}{{ tax.percent ? '(' + tax.cant + '%) ' : ' ' }}
                            </td>
                            <td style="text-align: center" v-if="sale_article_shop.article_shops.article">
                              <i> + {{
                                  (tax.percent === true || tax.percent === 'true' || tax.percent === 1 || tax.percent === '1' ) ? parseFloat((sale_article_shop.subTotal - sale_article_shop.totalDiscount + sale_article_shop.totalModifier) * tax.cant / 100).toFixed(2) : parseFloat(tax.cant).toFixed(2)
                                }} {{ getCurrency }}
                              </i>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="2">{{ $vuetify.lang.t('$vuetify.pay.total').toUpperCase() }}</td>
                    <td><b>{{ parseFloat(sale_article_shop.totalPrice).toFixed(2) + ' ' + getCurrency }}</b></td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
            </div>
            <v-row>
              <v-col cols="12" md="6" class="price" style="border-right: black">{{ $vuetify.lang.t('$vuetify.pay.sub_total') }}</v-col>
              <v-col cols="12" md="6" class="price" style="text-align: right">{{ parseFloat(editSale.subTotal).toFixed(2) + ' ' + getCurrency }}</v-col>
            </v-row>
            <table style="width: 100%"  v-if="editSale.discounts.length > 0">
              <tbody>
              <template v-if="editSale.discounts.length > 0">
                <tr v-for="(tax, k) in editSale.discounts"
                    :key="k">
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    <b>{{ $vuetify.lang.t('$vuetify.menu.discount') }}({{ tax.name_complete }}):</b>
                  </td>
                  <td v-if="tax.percent==='true' || tax.percent===true || tax.percent===1 || tax.percent==='1'"
                      class="price"
                      style="text-align: right"
                  >
                    <i>{{ -parseFloat(tax.cant * (editSale.subTotal) / 100).toFixed(2) + ' ' + getCurrency }}
                    </i>
                  </td>
                  <td v-else
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ -tax.cant + ' ' + getCurrency }}
                    </i>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <table style="width: 100%"  v-if="editSale.taxes.length > 0">
              <tbody>
              <template>
                <tr v-for="(tax, k) in localTaxesArticle"
                    :key="k">
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    <b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }}):</b>
                  </td>
                  <td
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ tax.total + ' ' + getCurrency }}</i>
                  </td>
                </tr>
              </template>
              <template v-if="editSale.taxes.length > 0">
                <tr v-for="(tax, k) in editSale.taxes"
                    :key="k">
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    <b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }}):</b>
                  </td>
                  <td v-if="tax.percent==='true'"
                      class="price"
                      style="text-align: right"
                  >
                    <i>{{ parseFloat(tax.cant * (sub_total) / 100).toFixed(2) + ' ' + getCurrency }} ({{ tax.cant }}%)
                    </i>
                  </td>
                  <td
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ tax.cant + ' ' + getCurrency }}
                    </i>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <v-divider/>
            <v-row>
              <v-col cols="12" md="6" class="price" style="border-right: black">{{ $vuetify.lang.t('$vuetify.pay.total') }}</v-col>
              <v-col cols="12" md="6" class="price" style="text-align: right"><b>{{ parseFloat(editSale.totalPrice).toFixed(2)  + ' ' + getCurrency }}</b> </v-col>
            </v-row>
            <v-divider/>
            <v-divider/>
            <v-divider/>
            <b style="margin-top: 10px" v-if="localTaxesArticle.length > 0">{{ $vuetify.lang.t('$vuetify.report.breakdown').toUpperCase() }} ({{$vuetify.lang.t('$vuetify.articles.taxes')}})</b>
            <table style="width: 100%"  v-if="localTaxesArticle.length > 0">
              <tbody>
                <template>
                <tr v-for="(tax, k) in localTaxesArticle"
                    :key="k">
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    <b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }}):</b>
                  </td>
                  <td
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ tax.total + ' ' + getCurrency }}</i>
                  </td>
                </tr>
              </template>
                <template v-if="editSale.taxes.length > 0">
                <tr v-for="(tax, k) in editSale.taxes"
                    :key="k">
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    <b>{{ $vuetify.lang.t('$vuetify.articles.tax') }}({{ tax.name }}):</b>
                  </td>
                  <td v-if="tax.percent==='true'"
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ parseFloat(tax.cant * (sub_total) / 100).toFixed(2) + ' ' + getCurrency }} ({{ tax.cant }}%)
                    </i>
                  </td>
                  <td
                    class="price"
                    style="text-align: right"
                  >
                    <i>{{ tax.cant + ' ' + getCurrency }}
                    </i>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <b style="margin-top: 10px">{{ $vuetify.lang.t('$vuetify.report.breakdown').toUpperCase() }} ({{ $vuetify.lang.t('$vuetify.menu.pay') }})</b>
            <template v-for="(pay, h) in editSale.payments">
              <table style="width: 100%"
                     :key="h">
                <tbody>
                <tr>
                  <td
                    class="price"
                    style="border-right: black"
                  >
                    {{ $vuetify.lang.t('$vuetify.payment.' + pay.bank_payment.method) }}:
                  </td>
                  <td
                    class="price"
                    style="text-align: right"
                  >
                    {{ `${parseFloat(pay.cant).toFixed(2)}` }} {{ pay.currency_id ? pay.currency.currency : getCurrency }}
                  </td>
                </tr>
                </tbody>
              </table>
              <table :key="(h + editSale.payments.length)"
                style="width: 100%"
              >
                <thead>
                <tr>
                  <th class="cantidad">
                    {{ $vuetify.lang.t('$vuetify.payment.cant_pay').toUpperCase() }}
                  </th>
                  <th class="producto">
                    {{ $vuetify.lang.t('$vuetify.payment.cant_charge').toUpperCase() }}
                  </th>
                  <th class="precio">
                    {{ $vuetify.lang.t('$vuetify.payment.cant_back') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr :key="h">
                  <td
                    class="cantidad"
                    style="text-align: center"
                  >
                    {{ parseFloat(pay.cant_pay).toFixed(2) }} {{
                      `${pay.bank_payment.method === 'cash' ? pay.currency_id !== null ? pay.currency.currency : getCurrency : getCurrency}`
                    }}
                  </td>
                  <td
                    class="producto"
                    style="text-align: center"
                  >
                    <div>{{ parseFloat(pay.cant).toFixed(2) + ' ' + getCurrency }}</div>
                  </td>
                  <td
                    class="price"
                    style="text-align: center"
                  >
                    {{ parseFloat(pay.cant_back).toFixed(2) }} {{ `${pay.cant_back ? getCurrency : ''}` }}
                  </td>
                </tr>
                </tbody>
              </table>
            </template>

            <p class="centrado">
              <b>{{ editSale.create ? $vuetify.lang.t("$vuetify.articles.sell_by") + ": " : '' }}</b>
              {{ editSale.create ? editSale.create.firstName : '' }}
              {{ editSale.create ? editSale.create.lastName ? editSale.create.lastName : "" : '' }}<br>
              <i v-if="user.company.footer"> <b>{{ user.company.footer.toUpperCase() }}</b></i>
            </p>
            <p class="centrado">
              <i v-if="user.company.address">{{
                  $vuetify.lang.t('$vuetify.shop_address') + ': ' + user.company.address
                }}</i><br>
              {{
                $vuetify.lang.t('$vuetify.report.contact_us') + ': ' + ((user.company.email) ? user.company.email : '')
              }}<br>
              {{ $vuetify.lang.t('$vuetify.phone') + ': ' + ((user.company.phone) ? user.company.phone : '') }}<br>
              {{ ((user.company.fiscal_account.rnc) ? 'RNC:' + user.company.fiscal_account.rnc : '') }}<br>
            </p><br>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mb-2"
            @click="cancelModal"
          >
            <v-icon>mdi-close</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            @click="printFacture"
          >
            <v-icon>mdi-printer</v-icon>
            {{ $vuetify.lang.t('$vuetify.actions.print') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import printJS from 'print-js'

export default {
  name: 'PrintFiscal',
  props: {
    id: {
      type: String,
      default: ''
    },
    go: {
      type: Boolean,
      default: false
    },
    routeGo: {
      type: String,
      default: 'vending'
    }
  },
  data () {
    return {
      localSubTotal: 0,
      totalTax: 0,
      localTaxesArticle: [],
      totalDisc: 0,
      total: 0,
      sub_total: 0
    }
  },
  computed: {
    ...mapState('sale', ['editSale']),
    ...mapState('discount', ['discounts']),
    ...mapGetters('auth', ['user', 'userPin', 'fiscalConfig']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    }
  },
  async created () {
    await this.getDiscounts()
    this.totalTax = 0
    this.totalDisc = 0
    this.total = 0
    this.sub_total = 0
    this.editSale.sale_article_shops.forEach(v => {
      v.sale_article_taxes.forEach(vT => {
        const tax = this.localTaxesArticle.filter(t => (t.id === vT.id))
        if (tax.length > 0) {
          const t = tax[0]
          t.total = parseFloat(parseFloat(vT.percent ? ((v.subTotal - v.totalDiscount + v.totalModifier) * vT.cant / 100) : vT.cant) + parseFloat(t.total)).toFixed(2)
        } else {
          const t = {
            name: vT.name,
            id: vT.id,
            total: vT.percent ? parseFloat((v.subTotal - v.totalDiscount + v.totalModifier) * vT.cant / 100).toFixed(2) : parseFloat(vT.cant).toFixed(2)
          }
          this.localTaxesArticle.push(t)
        }
      })
      this.localSubTotal = parseFloat(v.subTotal) + this.localSubTotal
      this.sub_total = parseFloat(v.totalPrice) + this.sub_total
    })
    this.editSale.taxes.forEach(v => {
      this.totalTax += v.totalTax
    })
    this.editSale.discounts.forEach(v => {
      this.totalDisc += v.totalDiscount
    })
    this.total = (this.sub_total + parseFloat(this.totalTax) - parseFloat(this.totalDisc)).toFixed(2)
    this.total = parseFloat(this.total).toFixed(2)
    this.$emit('updateData')
  },
  methods: {
    ...mapActions('sale', ['toogleShowModal']),
    ...mapActions('discount', ['getDiscounts']),
    // eslint-disable-next-line camelcase
    getVariantProperties (articleShops) {
      return articleShops ? article_shops.article ? article_shops.article.variants : [] : []
    },
    getUM (articleShops) {
      let um = 'u'
      if (articleShops.um) {
        um = articleShops.um.name
      }
      return um
    },
    total_pay (item) {
      let sum = 0
      item.taxes.forEach(v => {
        sum += v.percent
          ? (item.cant * item.price * v.cant) / 100
          : v.cant
      })
      let discount = 0
      item.discount.forEach(v => {
        discount += v.percent
          ? (item.cant * item.price * v.cant) / 100
          : v.cant
      })
      return item.cant * item.price + sum - discount - item.moneyRefund
    },
    printFacture () {
      let style = '.profile { width: 100px; height: 100px; border-radius: 50%; }' +
        '* { font-size: 12px; font-family: "DejaVu Sans", serif;}' +
        ' .profile { width: 100px; height: 100px; border-radius: 50%; }' +
        ' .centrado { text-align: center; align-content: center; }' +
        ' td, th, tr, table { border-collapse: collapse; margin: 15 auto;}' +
        'td.producto, th.producto { width: 80px; max-width: 80px; }' +
        'td.cantidad, th.cantidad { margin-left: 0px; width: 40px; max-width: 40px; word-break: break-all;}' +
        'td.precio, th.precio { width: 50px; max-width: 50px; word-break: break-all;}' +
        '#ticket { padding-top: 5px; align-content: center; width: 75mm; max-width: 75mm;}' +
        'img { max-width: inherit; width: inherit;}'
      if (this.editSale.type === 'cancelled') {
        style += '#ticket p:before{ content: "Cancenlada"; top: 0; bottom: 0; left: 0;' +
            'right: 0; z-index: 10; color: #d02710; font-size: 50px; font-weight: 50px; display: grid;' +
            'opacity: 0.5; transform: rotate(-45deg);}'
      }
      printJS({
        printable: 'ticket',
        type: 'html',
        // targetStyles: ['*'],
        style: style,
        scanStyles: false,
        header: '',
        onPrintDialogClose: () => {
          this.toogleShowModal(false)
          if (this.go) {
            this.$router.push({ name: this.routeGo })
          }
        },
        onError: () => {
          this.toogleShowModal(false)
          if (this.go) {
            this.$router.push({ name: this.routeGo })
          }
        }
      })
    },
    cancelModal () {
      this.toogleShowModal(false)
      if (this.go) {
        this.$router.push({ name: this.routeGo })
      }
    }
  }
}
</script>

<style scoped>
.profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

* {
  font-size: 12px;
  font-family: 'DejaVu Sans', serif;
}

td,
th,
tr,
table {
  border-collapse: collapse;
  margin: 15 auto;
}

td.producto,
th.producto {
  width: 80px;
  max-width: 80px;
}

td.cantidad,
th.cantidad {
  margin-left: 0px;
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

td.precio,
th.precio {
  width: 50px;
  max-width: 50px;
  word-break: break-all;
}

.centrado {
  text-align: center;
  align-content: center;
}

#ticket {
  padding-top: 5px;
  align-content: center;
  width: 75mm;
  max-width: 75mm;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  @page {
    margin: 0;
  }

  body {
    margin: 1.6cm;
  }

  .profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  * {
    font-size: 12px;
    font-family: 'DejaVu Sans', serif;
  }

  td,
  th,
  tr,
  table {
    border-collapse: collapse;
    margin: 15 auto;
  }

  td.producto,
  th.producto {
    width: 80px;
    max-width: 80px;
  }

  td.cantidad,
  th.cantidad {
    margin-left: 0px;
    width: 40px;
    max-width: 40px;
    word-break: break-all;
  }

  td.precio,
  th.precio {
    align-content: right;
    width: 50px;
    max-width: 50px;
    word-break: break-all;
  }

  .centrado {
    text-align: center;
    align-content: center;
  }

  #ticket {
    padding-top: 5px;
    align-content: center;
    width: 75mm;
    max-width: 75mm;
  }

  img {
    max-width: inherit;
    width: inherit;
  }
}

#watermark p:before{
  content: 'Cancenlada';
  /*position: fixed;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  color: #d02710;
  font-size: 50px;
  font-weight: 50px;
  display: grid;
  /*justify-content: center;*/
  /*align-content: center;*/
  opacity: 0.5;
  transform: rotate(-45deg);
}
</style>
