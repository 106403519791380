<template>
  <v-dialog
    v-model="toogleNewModal"
    max-width="450"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
            $vuetify.lang.t("$vuetify.actions.refund")
          }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row>
            <!--v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="bank"
                :items="banks"
                :label="$vuetify.lang.t('$vuetify.menu.bank')"
                item-text="name"
                item-value="value"
                required
                return-object
              />
            </v-col-->
            <v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <v-autocomplete
                style="margin-top: 15px;"
                v-model="newRefund.bank_payment"
                :filter="customFilter"
                :items="payments"
                :label="$vuetify.lang.t('$vuetify.payment.name')"
                auto-select-first
                item-text="name"
                return-object
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field-money
                v-model="newRefund.money"
                :label="$vuetify.lang.t('$vuetify.sale.cant_money')"
                :rules="formRule.required"
                required
                :properties="{
                  hint:$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(newRefund.money).toFixed(2))
                }"
                :options="{
                  length: 15,
                  precision: 2,
                  empty: 0.0
                }"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="newRefund.variant_cost"
                :filter="customFilter"
                :items="newRefund.article.sale_variant_cost"
                :label="$vuetify.lang.t('$vuetify.menu.variant_cost')"
                auto-select-first
                item-text="ref"
                return-object
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field-money
                v-model="newRefund.cant"
                :label="$vuetify.lang.t('$vuetify.sale.cant_article')"
                :rules="formRule.required"
                required
                :properties="{
                  min:0.00,
                  hint:$vuetify.lang.t('$vuetify.hints.round', 2, parseFloat(newRefund.cant).toFixed(2))
                }"
                :options="{
                  length: 15,
                  precision: 2,
                  empty: 0.0,
                  min:0.00,
                }"
              />
            </v-col>
            <v-col
              v-if="newRefund.sale.type === 'sale'"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="newRefund.box"
                :items="localBoxes"
                item-text="name"
                required
                auto-select-first
                :rules="formRule.required"
                :label="$vuetify.lang.t('$vuetify.menu.box')"
                return-object
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="toogleNewModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
        </v-btn>
        <v-btn
          :disabled="
            !formValid || isActionInProgress || !disabledButon
          "
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="handlerRefund"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <new-box v-if="this.$store.state.boxes.showNewModal" />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NewBox from '../../boxes/commerce/NewBox'

export default {
  name: 'NewRefound',
  components: { NewBox },
  data () {
    return {
      payments: [],
      formValid: false,
      localBoxes: [],
      bank: {},
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('refund', ['saved', 'newRefund', 'isActionInProgress']),
    ...mapState('boxes', ['boxes', 'showNewModal']),
    ...mapState('bank', ['saved', 'banks']),
    disabledButon () {
      return this.newRefund.cant > 0 || this.newRefund.money > 0
    },
    totalCantArticleRefund () {
      let totalCantRefund = 0
      this.newRefund.article.refunds.forEach(v => {
        totalCantRefund += parseFloat(v.cant)
      })
      return totalCantRefund
    },
    totalCantMoneyRefund () {
      let totalMoneyRefund = 0
      this.newRefund.article.refunds.forEach(v => {
        totalMoneyRefund += parseFloat(v.money)
      })
      return totalMoneyRefund
    }
  },
  watch: {
    boxes: function () {
      this.getLocalBoxes()
    },
    'newRefund.cant': function (v1) {
      let totalCantRefund = 0
      this.newRefund.article.refunds.forEach(v => {
        totalCantRefund += parseFloat(v.cant)
      })
      if (parseFloat(v1) > parseFloat(this.newRefund.article.cant - totalCantRefund)) {
        this.newRefund.cant = parseFloat(this.newRefund.article.cant - totalCantRefund).toFixed(2)
      } else {
        this.newRefund.cant = parseFloat(v1).toFixed(2)
      }
      this.newRefund.cant = parseFloat(this.newRefund.cant).toFixed(2)
    },
    'newRefund.money': function (v1) {
      let totalMoneyRefund = 0
      this.newRefund.article.refunds.forEach(v => {
        totalMoneyRefund += parseFloat(v.money)
      })
      if (v1 > this.newRefund.article.price * this.newRefund.article.cant - totalMoneyRefund) { this.newRefund.money = this.newRefund.article.cant * this.newRefund.article.price - totalMoneyRefund }
    },
    banks: function () {
      this.bank = this.banks[0]
    }
  },
  async created () {
    this.newRefund.variant_cost = this.newRefund.article.sale_variant_cost[0]
    if (this.newRefund.sale.type === 'sale') {
      await this.getBoxes()
    }
    await this.getBanks()
    this.banks.forEach((v) => {
      v.payments_banks.forEach((p) => {
        this.payments.push({
          name: '(' + v.name + ') ' + this.$vuetify.lang.t('$vuetify.payment.' + p.payment.method),
          type: p.payment.name,
          method: p.payment.method,
          bank_id: v.id,
          payment_id: p.id
        })
      })
    })
  },
  methods: {
    ...mapActions('refund', ['toogleNewModal', 'createRefund']),
    ...mapActions('boxes', ['getBoxes']),
    ...mapActions('bank', ['getBanks']),
    customFilter (item, queryText, itemText) {
      return (
        this.$vuetify.lang
          .t('$vuetify.payment.' + item.method)
          .toLowerCase()
          .indexOf(queryText.toLowerCase()) > -1
      )
    },
    async handlerRefund () {
      let totalCantRefund = 0
      let totalMoneyRefund = 0
      this.newRefund.article.refunds.forEach(v => {
        totalCantRefund += parseFloat(v.cant)
        totalMoneyRefund += parseFloat(v.money)
      })
      if (this.newRefund.cant > this.newRefund.article.cant - totalCantRefund || this.newRefund.cant < 0) {
        this.showMessage(this.$vuetify.lang.t('$vuetify.messages.warning_refund_Cant', [totalCantRefund],
          [parseFloat(this.newRefund.article.cant - totalCantRefund).toFixed(2)])
        )
      } else if (this.newRefund.money > this.newRefund.article.cant * this.newRefund.article.price - totalMoneyRefund || this.newRefund.money < 0
      ) {
        this.showMessage(
          this.$vuetify.lang.t('$vuetify.messages.warning_refund_Money', [totalMoneyRefund],
            [parseFloat(this.newRefund.article.cant * this.newRefund.article.price - totalMoneyRefund).toFixed(2)]
          )
        )
      } else if (this.$refs.form.validate()) {
        this.loading = true
        await this.createRefund(this.newRefund)
          .then(() => {
            this.$emit('reloadData')
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    getLocalBoxes () {
      this.localBoxes = []
      if (this.newRefund.sale.shop) {
        this.localBoxes = this.boxes.filter(shp => shp.id === this.newRefund.sale.shop.id)[0].boxes
        if (this.localBoxes.length > 0) {
          this.newRefund.box = this.localBoxes[0]
        }
      }
    },
    showMessage (textMsg) {
      this.$Swal.fire({
        title: this.$vuetify.lang.t('$vuetify.actions.refund', [
          this.$vuetify.lang.t('$vuetify.menu.articles')
        ]),
        text: textMsg,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: this.$vuetify.lang.t(
          '$vuetify.actions.accept'
        ),
        confirmButtonColor: 'red'
      })
    }
  }
}
</script>

<style scoped></style>
